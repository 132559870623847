<template>
  <div v-cloak>
    <template>
      <div class="modal" :class="[ isActive ? 'is-active' : '', textClass ]">
        <div class="modal-background"></div>
        <div class="modal-card">
          <div class="modal-card-body">
            <slot :toggle="toogleModal" />
          </div>
        </div>
        <button
          class="modal-close is-large"
          @click="toogleModal()"
          aria-label="close"
        ></button>
      </div>
    </template>
    <a
      v-if="!isActive"
      :class="classButton"
      @click="toogleModal()"
      v-html="contentButton"
    />
  </div>
</template>

<script>
export default {
  name: "Modal",
  props: {
    textButton: {
      type: String,
      default: "Submit",
    },
    classButton: {
      type: String,
      default: "button is-primary",
    },
    iconClass: {
      type: String,
      default: "",
    },
    openToLoad: {
      type: Boolean,
      required: false,
    },
    textClass: {
      type: String,
      default: 'has-text-blue-darkest'
    }
  },
  data() {
    return {
      isActive : false
    };
  },
  mounted() {
    this.isActive = !(
        typeof this.openToLoad === "undefined" || this.openToLoad === false
      );
  },
  computed: {
    contentButton() {
      return this.iconClass.length === 0
        ? this.textButton
        : `<i class="${this.iconClass}"></i><span>${this.textButton}</span>`;
    },
  },
  methods: {
    toogleModal() {
      this.isActive = !this.isActive;
    },
  },
};
</script>
